let SignaturePad = require("signature_pad").default;
require('jquery-validation');
const Fingerprint2 = require('fingerprintjs2')
console.log(SignaturePad);


$(function () {

    $(".signature-pad-container").each(function () {

        let input=$("input.data",$(this))
        let canvas = $("canvas.signature-pad",$(this))
        let typeName = $(".signature-type-name", $(this))
        let drawHolder = $("canvas.draw-image",$(this)).get()[0].getContext('2d');

        let font="Caveat";
        let fontSize=60;
        drawHolder.canvas.height=fontSize*1.5


        console.log({input, drawHolder, typeName})

        typeName.change(()=>{
            let value = typeName.val();
            drawHolder.font = `${fontSize}px ${font}`;
            drawHolder.canvas.width = drawHolder.measureText(value).width*1.2;

            drawHolder.font = `${fontSize}px ${font}`;
            drawHolder.fillText(value, 0, fontSize);
            //$("img.testing",$(this)).get()[0].src = drawHolder.canvas.toDataURL();
            input.val(drawHolder.canvas.toDataURL());
        })

        window.canvass = canvas.get()[0];
        var signaturePad = new SignaturePad(canvas.get()[0],{
            onEnd: ()=>{
                input.val(signaturePad.toDataURL());
            }
        });

        $(".draw-tab-button").click(()=>{
            // Unbinds all event handlers
            //signaturePad.off();
            let c = canvas.get()[0];
            window.canvass = c;
            console.log([
            c.width = c.offsetWidth = c.parentElement.parentElement.offsetWidth,
            c.height = c.offsetHeight = c.parentElement.parentElement.offsetHeight
        ])
            // Rebinds all event handlers
            //signaturePad.on();
        })

    })



    window.toggleSubform = function(id){
        $(".role-subform").hide()
        $("#role-form-"+id).show()
    }
    window.toggleSubform();


    let submitAttempts = 0;

    $("form.needs-validation").validate({
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function(form) {
            submitAttempts++;
            updateBrowserInfoField()
            $(':hidden input').remove()
            form.submit();
        },
        invalidHandler: function(form){
            submitAttempts++;
            $(this).addClass('was-validated');
        },
        validClass: "valid-feedback",
        errorClass: "invalid-feedback",
        highlight: function ( element, errorClass, validClass ) {
            $( element ).addClass('is-invalid').removeClass('is-valid').parents( ".col-sm-5" ).addClass( "has-error" ).removeClass( "has-success" );
        },
        unhighlight: function (element, errorClass, validClass) {
            $( element ).addClass('is-valid').removeClass('is-invalid').parents( ".col-sm-5" ).addClass( "has-success" ).removeClass( "has-error" );
        }
    });

    let fingerprint = false;
    if (window.requestIdleCallback) {
        requestIdleCallback(function () {
            Fingerprint2.get(function (components) {
                fingerprint = components
                updateBrowserInfoField({extendedJsFonts: true})
            })
        })
    } else {
        setTimeout(function () {
            Fingerprint2.get(function (components) {
                fingerprint = components
                updateBrowserInfoField({extendedJsFonts: true})
            })
        }, 500)
    }

    function updateBrowserInfoField(){
        try {
            let info = {
                submitAttempts,
                fingerprint,
                browserTime: new Date().toString()
            };
            ["appCodeName", "appName", "appVersion", "language", "platform", "product", "productSub", "userAgent", "vendor", "userActivation"].forEach((name) => {
                try {
                    info[name] = window.navigator[name]
                    if (typeof info[name] === 'object') {
                        let real = {};
                        for (var i in info[name]) {
                            real[i] = info[name][i]
                        }
                        info[name] = real;
                    }
                }catch(e){}
            })
            try {
                info.plugins = [];
                for (var i in navigator.plugins) {
                    info.plugins.push(navigator.plugins[i].name)
                }
            }catch(e){
                console.error(e);
            }

            JSON.parse(JSON.stringify(info))

            $("input.browser-info-field").val(JSON.stringify(info))
        }catch(e){
            console.error(e);
        }

    }
    updateBrowserInfoField();


})